<template>
  <div>
    <b-row>
      <b-col
        v-for="(totem, key, index) in totem_group"
        md="12"
        lg="12"
        class="table_stand"
        :key="key"
      >
        <b-card-code
          :title="`${$t('totem.totem_area')} ${$t(`pop_up.${key}`)}`"
        >
          <b-row>
            <b-col md="6" lg="6">
              <b-table
                hover
                small
                :fields="fields"
                :items="totem"
                responsive="sm"
                @row-clicked="onRowClick"
              >
                <template #cell(ID)="data">
                  {{ data.item.id }}
                </template>
                <template #cell(name)="data">
                  {{ $t(`pop_up.${data.item.label}`) }}
                </template>
                <template #cell(media)="data">
                  <b-img
                    v-if="data.item.image_url != ''"
                    :src="data.item.image_url"
                    width="80"
                  />
                </template>
                <template #cell(action)="data">
                  <b-button
                    class="btn-icon rounded-circle"
                    variant="flat-primary"
                    @click="editTotem(data.item.id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>
            <b-col
              md="6"
              lg="6"
              style="display: flex; align-items: center; justify-content: center;"
            >
              <b-img
                :src="
                  require(`@/assets/images/pages/totem/mappa-totem-${index}-small.jpg`)
                "
                style="width: 65%;object-fit: contain;"
              >
              </b-img>
            </b-col>
          </b-row>
        </b-card-code>
      </b-col>
    </b-row>

    <modal-edit-totem
      ref="edit_totem"
      :totem_id="totem_id"
      @onSave="refreshTotemList"
      @open_editor="openEditor"
    ></modal-edit-totem>

    <modal-editor
      :image="image"
      :type="type"
      :width="width"
      :height="height"
      @onSave="showPreview"
      @close="abortImageEdit"
    ></modal-editor>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BTable,
  BButton,
  BCard,
  BCardText,
  BLink,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BModal,
  VBModal,
  BFormSelect,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { getAllTotems } from "@api/totems.js";
import ModalEditTotem from "./ModalEditTotem.vue";
import ModalEditor from "../editor.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BModal,
    VBModal,
    BFormSelect,
    BAvatar,
    ModalEditor,
    ModalEditTotem,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      image: null,
      type: null,
      totem_id: null,
      width: 720,
      height: 1280,
      totem_group: [],
      media_list: [],
      fields: [
        { key: "ID", label: "ID" },
        { key: "name", label: this.$t("field_label_name") },
        { key: "media", label: this.$t("field_label_media") },
        { key: "action", label: this.$t("field_label_actions") },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      getAllTotems().then((data) => {
        $this.totem_group = data;
      });
    },
    editTotem(id) {
      this.totem_id = id;
      this.$bvModal.show("modal-edit-totem");
    },
    onRowClick(record) {
      this.editTotem(record.id);
    },
    refreshTotemList() {
      this.getData();
    },
    openEditor(file, type) {
      if (file != null) {
        this.image = file;
        this.type = type;
        this.$bvModal.show("modal-editor");
      }
    },
    showPreview(type, imageUrl, image = null) {
      this.$refs.edit_totem.totem_file = image;
      this.$refs.edit_totem.totem_preview = imageUrl;
    },
    abortImageEdit() {
      this.$refs.edit_totem.emptyField();
    },
  },
};
</script>
