import { api } from "./index";

export const getAllTotems = () => {
  return new Promise((resolve, reject) => {
    api
      .get("totems")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getTotem = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get(`totems/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const updateTotem = (id, formData) => {
  formData.append("_method", "PUT");
  return new Promise((resolve, reject) => {
    api
      .post(`totems/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
