<template>
  <b-modal
    id="modal-edit-totem"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    hideHeaderClose
    no-close-on-backdrop
    @ok.prevent="saveData"
    @cancel="resetModal"
    @shown="getData()"
    @show="showModal"
    size="lg"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder">
            {{ $t(`pop_up.${totem_name}`) }}
          </h2>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label-for="totem-name"
            :label="$t('totem.totem_name')"
            hidden
          >
            <div class="form-label-group">
              <b-form-input
                id="totem-name"
                v-model="totem_name"
                type="text"
                :placeholder="$t('totem.totem_name')"
                disabled
              />
            </div>
          </b-form-group>
          <b-form-group
            label-for="totem-media"
            :label="$t('totem.upload_media_totem')"
          >
            <div class="form-label-group">
              <b-form-file
                id="edit-totem-file"
                accept="image/*"
                v-model="totem_file"
                :placeholder="$t('any_file_select')"
                @input="openEditor(totem_file, 'edit_totem')"
              />
            </div>
          </b-form-group>
          <b-form-group label-for="totem-link" :label="$t('totem.link')" hidden>
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="ExternalLinkIcon" />
                </b-input-group-prepend>
                <b-form-input id="external-link" v-model="totem_url" />
              </b-input-group>
            </b-form-group>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="column-preview-container">
          <b-img
            :src="totem_preview"
            id="totem-preview-container"
            class="preview-img"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BFormFile,
  BOverlay,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { getTotem, updateTotem } from "@api/totems";
export default {
  props: ["totem_id"],
  name: "modal-edit-totem",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BFormFile,
    BOverlay,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      totem_name: null,
      totem_file: null,
      totem_preview: null,
      totem_url: null,
    };
  },
  methods: {
    showModal() {
      this.show = true;
      this.totem_name = null;
      this.totem_file = null;
      this.totem_preview = null;
      this.totem_url = null;
    },
    getData() {
      let $this = this;
      getTotem(this.totem_id)
        .then((data) => {
          $this.totem_name = data.label;
          $this.totem_url = data.url;
          if (data.image_name != "") {
            $this.totem_preview = data.image_url;
          }
          $this.show = false;
        })
        .catch((error) => {
          console.log(error);
          $this.show = false;
        });
    },
    saveData() {
      let $this = this;
      let formData = new FormData();
      formData.append("label", $this.totem_name);
      formData.append("url", $this.totem_url);
      if ($this.totem_file) {
        formData.append("image", $this.totem_file);
      }
      updateTotem($this.totem_id, formData).then((data) => {
        $this.$emit("onSave");
        $this.totem_preview = null;
        $this.$bvModal.hide("modal-edit-totem");
      });
    },
    resetModal() {
      this.totem_file = null;
      this.totem_preview = null;
      this.$bvModal.hide("modal-edit-totem");
    },
    openEditor(file, type) {
      this.$emit("open_editor", file, type);
    },
    emptyField() {
      this.totem_file = null;
    },
  },
};
</script>
